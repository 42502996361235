/* ========= RESPONSIVE ========= */

@media only screen and (max-width: 480px) {
  /* Small screens */

  .intro {
    white-space: normal;
    word-wrap: break-word;
    flex-direction: column;
  }

  #intro-text {
    margin-right: 0;
    font-size: 2rem;
  }

  #intro-name {
    font-size: 3.5rem;
  }

  #intro-description {
    font-size: 1rem;
  }

  .intro-links {
    margin-top: 1rem;
    gap: 5rem;
  }

  h3.intro {
    font-size: 1.25rem;
    max-width: 4rem;
  }

  .arrow {
    margin: 0.4rem;
    width: 3rem;
  }

  .video-container {
    width: 70%;
  }
}

@media only screen and (min-width: 480px) {
  /* Medium/Large screens */
  #intro-text {
    margin-right: 18px;
    font-size: 4.5rem;
  }

  #intro-name {
    font-size: 4.5rem;
  }

  #intro-description {
    font-size: 1.25rem;
  }

  .intro-links {
    margin-top: 1em;
    gap: 10em;
  }

  h3.intro {
    font-size: 1.25rem;
  }

  .video-container {
    width: 35%;
  }
}

/* ================================ */

.homepage-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  white-space: pre;
  width: 100%;
}

/* ========= LINKS ========= */

.intro-links {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.intro-links:hover {
  cursor: pointer;
}

.intro-sublinks {
  align-items: center;
  display: flex;
}

h3.intro {
  /* read about me, see my work */
  font-weight: 400;
  margin: 1vh 0.5vh;
  text-decoration: none;
}

/* ========= TEXTS ========= */

.intro-container {
  max-width: 75%;
  overflow-wrap: break-word;
}

.intro {
  display: flex;
  justify-content: center;
  text-align: center;
}

h1.intro {
  /* hi there, i'm anh mac.*/
  font-family: Epilogue;
  font-size: 4.5rem;
  margin: 0;
}

h2.intro {
  /* description */
  font-family: Inter;
  font-weight: 400;
  margin: 0;
  text-align: center;
}

#intro-text {
  /* hi there, i'm */
  font-weight: 400;
}

#intro-name {
  /* anh mac. */
  animation: gradient 5s ease infinite;
  background: linear-gradient(
    88deg,
    #94f8ca 17.65%,
    #8ae8e1 35.97%,
    #b6bef4 54.3%,
    #f9c3c2 70.91%,
    #f5eeb3 88.38%
  );
  background-clip: text;
  background-size: 200% 200%;
  font-weight: 700;
  -webkit-animation: gradient 5s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* ================================ */

/* ======= BACKGROUND VIDEO ======= */

.video-container {
  position: absolute;
  z-index: -9999;
  overflow: hidden;
}

.background-video {
  max-width: 250%;
}

.top-left {
  top: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  right: 0;
  float: right;
}

/* ================================ */

/* ==========  ANIMATION ========== */

.noise {
  animation: grain 7s steps(10) infinite;
  background-size: 200px;
  background-repeat: repeat;
  height: 600%;
  left: -150%;
  opacity: 0.25;
  pointer-events: none;
  position: fixed;
  width: 600%;
  top: -300%;
  z-index: -9999;
  -webkit-animation: grain 7s steps(10) infinite;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes grain {
  0%,
  to {
    transform: translate(0);
  }

  10% {
    transform: translate(-5%, -10%);
  }

  20% {
    transform: translate(-15%, 5%);
  }

  30% {
    transform: translate(7%, -25%);
  }

  40% {
    transform: translate(-5%, 25%);
  }

  50% {
    transform: translate(-15%, 10%);
  }

  60% {
    transform: translate(15%);
  }

  70% {
    transform: translateY(15%);
  }

  80% {
    transform: translate(3%, 35%);
  }

  90% {
    transform: translate(-10%, 10%);
  }
}

@keyframes grain {
  0%,
  to {
    transform: translate(0);
  }

  10% {
    transform: translate(-5%, -10%);
  }

  20% {
    transform: translate(-15%, 5%);
  }

  30% {
    transform: translate(7%, -25%);
  }

  40% {
    transform: translate(-5%, 25%);
  }

  50% {
    transform: translate(-15%, 10%);
  }

  60% {
    transform: translate(15%);
  }

  70% {
    transform: translateY(15%);
  }

  80% {
    transform: translate(3%, 35%);
  }

  90% {
    transform: translate(-10%, 10%);
  }
}

/* ================================ */

@media only screen and (max-width: 1024px) {
  .about-container {
    overflow: visible;
  }

  .inner-container {
    display: flex;
    flex-direction: column;
  }

  .about-left {
    margin-top: 7rem;
    margin-left: 4rem;
    margin-right: 4rem;
    z-index: 10;
  }

  .about-title {
    font-size: 2.5rem;
  }

  .paragraph {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .about-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: -10;
  }

  .circle {
    width: 18rem;
    height: 18rem;
    border-radius: 18rem;
  }

  .image-background {
    position: absolute;
    height: 30rem;
    bottom: -6rem;
    padding-left: 1rem;
  }

  .image-me {
    position: absolute;
    height: 29rem;
    bottom: -5.5rem;
  }
}

@media only screen and (min-width: 1025px) {
  /* Medium/Large screens */
  .about-container {
    overflow: hidden;
    justify-content: center;
  }

  .inner-container {
    display: inline-flex;
  }

  .about-left {
    width: 100%;
    height: 100vh;
    display: flex;
  }

  .about-text-container {
    display: flex;
    align-items: left;
    justify-content: center;
    margin-left: 150px;
    flex-direction: column;
    margin-right: 50px;
  }

  .about-title {
    font-size: 3.125rem;
  }

  .paragraph {
    font-size: 1rem;
    line-height: 1.875rem;
  }

  .about-right {
    display: flex;
    width: 80%;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  .circle {
    width: 525px;
    height: 525px;
    border-radius: 525px;
  }

  .image-background {
    position: absolute;
    height: 100vh;
    bottom: -15px;
    padding-left: 30px;
  }

  .image-me {
    position: absolute;
    height: 100vh;
    bottom: -1rem;
  }
}

.about-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
}

.inner-container {
  align-items: center;
  justify-content: center;
}

.about-title {
  margin: 0;
}

.paragraph {
  width: 100%;
  color: #000;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
}

.circle {
  background: linear-gradient(
    59deg,
    var(--Green) 4.82%,
    var(--Teal) 28.15%,
    var(--Purple) 51.82%,
    var(--Pink) 72.66%,
    var(--Yellow) 94.78%
  );
  background-size: 200% 200%;
  box-shadow: var(--box-shadow-light-inset);
  animation: gradient 5s ease infinite;
  -webkit-animation: gradient 5s ease infinite;
}

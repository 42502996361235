@media only screen and (max-width: 480px) {
  /* Small screens */
  .logo-container {
    margin-top: 1rem;
  }

  img.logo {
    width: 1.75rem;
  }

  .card-title {
    font-size: 1.3rem;
  }

  .card-subtitle {
    font-size: 1rem;
  }

  .title-container {
    margin-bottom: 1rem !important;
  }
}

@media only screen and (min-width: 481px) {
  /* Medium/Large screens */
  .card-title {
    font-size: 1.563rem;
  }

  .card-subtitle {
    font-size: 1.125rem;
  }

  .title-container {
    margin-bottom: 0.938rem;
  }
}

.card {
  width: 100%;
  height: 90%;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 1px solid rgba(255, 255, 255, 0.34);
  background: var(--Purple, #b6bef4);
  /* box-shadow: 0px 5px 5px 0px rgba(112, 112, 112, 0.25); */
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  padding: 2.5rem 2.5rem 5rem 2.5rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
}

.logo {
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.card-title {
  font-family: Arial;
  margin: 0;
  cursor: default;
}

.card-subtitle {
  font-family: Arial;
  font-weight: 400;
  margin: 0;
  cursor: default;
}

.categories-container {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.disabled {
  filter: invert(74%) sepia(8%) saturate(0%) hue-rotate(150deg) brightness(85%)
    contrast(83%);
  pointer-events: none;
}

.error-message {
  color: red;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0;
}

.logo-container {
  display: inline-flex;
  align-items: flex-end;
  gap: 10px;
  align-items: flex-start;
}

.title-container {
  display: flex;
  justify-content: space-between;
}

.expand {
  padding-bottom: 2rem !important;
}

.description {
  font-family: Arial;
  margin-top: 1.2rem;
  font-size: 0.85rem;
  line-height: 1.1rem;
}

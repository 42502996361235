.pill-container {
  border-radius: 25px;
  background: #fff;
}

.pill-text {
  color: #000;
  text-align: center;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 7px 12px;
}

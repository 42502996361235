@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap");

:root {
  --Green: #94f8ca;
  --Purple: #b6bef4;
  --Teal: #8ae8e1;
  --Pink: #f9c3c2;
  --Yellow: #f5eeb3;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
  --border-radius: 30px;
  --box-shadow-light-inset: inset 6px 6px 10px rgba(0, 0, 0, 0.1),
    inset -6px -6px 10px rgba(255, 255, 255, 0.7);

  font-size: 16px;
}

body {
  font-family: "Epilogue", "Inter";
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* IE/Edge */
}

body::-webkit-scrollbar {
  display: none !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
}

.title {
  color: #000;
  font-family: Epilogue;
  font-size: 75px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 50px;
}

/* ========= RESPONSIVE ========= */

@media only screen and (max-width: 480px) {
  /* Small screens */
  .control-button {
    top: 1.5rem;
    right: 1.5rem;
  }

  .text-item {
    font-size: 3rem;
  }

  .links {
    font-size: 1.2rem;
  }

  img.links {
    width: 1.2rem;
  }
}

@media only screen and (min-width: 481px) {
  /* Medium/Large screens */
  .control-button {
    top: 3.125rem;
    right: 3.125rem;
  }

  .text-item {
    font-size: 4.5rem;
  }

  .links {
    font-size: 1.5rem;
  }
}

/* ================================ */

.menu-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 9998;
}

/* ========= CONTROL BUTTON ========= */

.control-button {
  position: fixed;
  filter: invert(99%) sepia(1%) saturate(4%) hue-rotate(329deg) brightness(107%)
    contrast(102%);
  z-index: 9999;
}

.control-button:hover {
  cursor: pointer;
}

.inactive {
  pointer-events: none;
}

/* ================================ */

/* ============ LINKS ============ */

.links {
  color: var(--Gray-3, #828282);
  display: flex;
  text-decoration: none;
}

h2.links {
  margin-right: 3px;
}

.links:hover {
  cursor: pointer;
}

.links-container {
  display: flex;
  justify-content: space-between;
}

/* ================================ */

/* ========= MAIN CONTENT ======== */

.content {
  align-items: center;
  animation: gradient 5s ease infinite;
  background: linear-gradient(
    111deg,
    #94f8ca 17.65%,
    #8ae8e1 35.97%,
    #b6bef4 54.3%,
    #f9c3c2 70.91%,
    #f5eeb3 88.38%
  );
  background-size: 200% 200%;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  -webkit-animation: gradient 5s ease infinite;
  width: 100%;
  transition: 0.3s ease-out;
}

.content-inactive {
  opacity: 0;
}

.content-active {
  opacity: 1;
}

/* ================================ */

/* ============= TEXTS ============= */

.text-container {
  display: inline-flex;
  flex-direction: column;
  gap: 25px;
}

.text-item {
  font-family: Epilogue;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  text-shadow:
    1px 0 #000,
    -1px 0 #000,
    0 1px #000,
    0 -1px #000;
}

.text-item:hover {
  cursor: pointer;
}

#home {
  color: var(--Green, #94f8ca);
}

#work {
  color: var(--Purple, #b6bef4);
}

#about {
  color: var(--Pink, #f9c3c2);
}

#contact {
  color: var(--Teal, #8ae8e1);
}

/* ================================ */

/* ==========  ANIMATION ========== */

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* ================================ */
@media only screen and (max-width: 480px) {
  /* Small screens */
  .title-container {
    margin: 0;
  }

  .work-title {
    margin-top: 6rem;
    margin-left: 4rem;
    font-size: 2.5rem;
  }

  .projects {
    margin-top: 10rem;
  }

  .cards-container {
    margin: 0 2rem;
    width: 100%;
  }

  .card-container {
    top: 10rem;
    margin-top: -4.5rem;
  }
}

@media only screen and (min-width: 481px) {
  .work-title {
    margin-top: 11.25rem;
    margin-left: 6.25vw;
    font-size: 4.688rem;
  }

  .projects {
    margin-top: 13.75rem;
  }

  .cards-container {
    margin-left: 6.25vw;
    width: 50%;
    margin-top: 4rem;
  }

  .card-container {
    top: 18rem;
    margin-top: -3rem;
  }
}

.work-container {
  display: flex;
}

.work-title {
  position: fixed;
}

.projects {
  display: flex;
  width: 100%;
}

.card-container {
  position: sticky;
}

.card-container:first-child {
  margin-top: 0;
}

.project-images-container {
  width: 50%;
}

.project-image {
  margin-left: 0.625rem;
  max-width: 90%;
  height: 90vh;
  position: fixed;
  border-radius: var(--border-radius);
}

.project-image img {
  /* animation: imageFadeIn 1s ease forwards; */
  object-fit: cover;
  width: 45%;
  height: 75%;
  right: -1.25rem;
  border-radius: var(--border-radius);
}

@keyframes imageFadeIn {
  0% {
    opacity: 0;
    transform: none;
  }
  100% {
    opacity: 1;
    transform: scale(1.1) translateZ(0px);
  }
}
